import { Text } from "@clipboard-health/ui-react";
import BoltIcon from "@mui/icons-material/Bolt";
import CalendarTodayIcon from "@mui/icons-material/CalendarMonth";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Drawer, IconButton, Stack } from "@mui/material";
import { useState } from "react";

const ICON_COLOR_STYLE = { color: "#424242" };

function AteamBenefitItem(props: {
  startIcon: React.ReactNode;
  label: string;
  onClick: () => void;
}) {
  const { startIcon, label, onClick } = props;

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      borderBottom="1px solid #424242"
      gap={0.5}
      padding={0.5}
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {startIcon}
      <Text sx={ICON_COLOR_STYLE}>{label}</Text>
      <ChevronRightIcon sx={{ marginLeft: "auto", color: "#424242" }} />
    </Stack>
  );
}

export function ATeamBenefits() {
  const [drawerState, setDrawerState] = useState({ visible: false, title: "", description: "" });

  const closeDrawer = () => {
    setDrawerState({ visible: false, title: "", description: "" });
  };

  return (
    <Stack gap={1}>
      <Text
        variant="h2"
        sx={{
          color: "#424242",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        Priority Plus Benefits
      </Text>
      <Stack gap={1} width="100%">
        <AteamBenefitItem
          startIcon={<CalendarTodayIcon sx={ICON_COLOR_STYLE} />}
          label="Early access to shifts"
          onClick={() => {
            setDrawerState({
              visible: true,
              title: "Early access to shifts",
              description:
                "As a professional with Priority Plus status, you'll have Priority Access to the workplace's shifts when they select you for their A-Team. Because a workplace has a limited number of A-Team professionals, Priority Plus status increases your chances of creating a consistent schedule at the workplaces you like most.",
            });
          }}
        />
        <AteamBenefitItem
          startIcon={<BoltIcon sx={ICON_COLOR_STYLE} />}
          label="Instant notifications when shifts are posted"
          onClick={() => {
            setDrawerState({
              visible: true,
              title: "Instant notifications when shifts are posted",
              description:
                "With Priority Plus status, you'll receive notifications about a workplace's new shifts as soon as they post. These instant updates ensure you can secure the best shifts.",
            });
          }}
        />
      </Stack>
      <Drawer
        anchor="bottom"
        open={drawerState.visible}
        PaperProps={{
          sx: {
            borderRadius: "25px 25px 0 0",
            padding: 2,
          },
        }}
        ModalProps={{ keepMounted: false }}
        onClose={closeDrawer}
      >
        <Stack gap={1}>
          <IconButton
            sx={{
              alignSelf: "flex-end",
              color: "#424242",
            }}
            size="small"
            onClick={closeDrawer}
          >
            <CancelOutlinedIcon />
          </IconButton>
          <Stack gap={2} marginBottom={3}>
            <Text
              variant="h2"
              sx={{
                color: "#424242",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {drawerState.title}
            </Text>
            <Text variant="body1" sx={{ color: "#424242" }}>
              {drawerState.description}
            </Text>
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
}
