import { InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, Card, CardContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { generatePath } from "react-router-dom";

export interface FacilityCardProps {
  facilityId: string;
  facilityName: string;
  aTeamRosterSize?: number;
}

export function FacilityCard(props: FacilityCardProps) {
  const { facilityId, facilityName, aTeamRosterSize } = props;

  function getAteamRosterText() {
    if (isDefined(aTeamRosterSize) && aTeamRosterSize > 0) {
      if (aTeamRosterSize === 1) {
        return "You are the only professional with Priority Plus at this workplace.";
      }

      if (aTeamRosterSize === 2) {
        return `Only 1 other professional has Priority Plus at this workplace.`;
      }

      return `Only ${
        aTeamRosterSize - 1
      } other professionals have Priority Plus at this workplace.`;
    }

    return "";
  }

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        padding: "8px",
        border: "1px solid #E0E0E0",
      }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "5px" },
          paddingTop: "0",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <Stack justifyContent="space-between" alignItems="center" gap={2}>
          <Text
            variant="h3"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              alignSelf: "flex-start",
              width: "100%",
              color: "#424242",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {facilityName}
          </Text>

          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
          >
            <Text variant="body2">{getAteamRosterText()}</Text>

            <InternalLink
              underline="none"
              variant="body2"
              to={generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
                facilityId,
              })}
              sx={{
                alignSelf: "flex-end",
              }}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.OPENED_A_TEAM_FACILITY_PROFILE, {
                  facilityId,
                });
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: "64px",
                  height: "32px",
                  color: "#6A67CE",
                  borderColor: "#6A67CE",
                  fontWeight: "600",
                  fontSize: "16px",
                  minWidth: "120px",
                }}
              >
                View Profile
              </Button>
            </InternalLink>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
