import { Text } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { AppBarHeader, BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { PageWithHeader } from "@src/appV2/lib/Layouts";

import { useGetATeamWorkplaces } from "../useGetATeamWorkplaces";
import { ATeamBenefits } from "./ATeamBenefits";
import { ATeamList } from "./ATeamList";

export function ATeamPage() {
  const { aTeamWorkplaces, isATeamWorkplaceLoading, refetchATeamWorkplaces } =
    useGetATeamWorkplaces();
  return (
    <IonPage>
      <PageWithHeader
        containerVariant="without-margin"
        containerMaxWidth="lg"
        appBarHeader={
          <AppBarHeader
            title="Priority Plus"
            leftCta={
              <BackButtonLink
                alwaysUseDefaultBackTo
                defaultBackTo={DeprecatedGlobalAppV1Paths.RATINGS}
              />
            }
          />
        }
        onRefresh={refetchATeamWorkplaces}
      >
        <Stack gap={4} padding={2}>
          <Stack gap={1}>
            <Text
              variant="h2"
              sx={{
                color: "#424242",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              Priority Plus
            </Text>
            <Text variant="body2">
              Priority Plus status is given by a workplace to a select few of their best performing
              professionals. Their A-Team!
            </Text>
          </Stack>
          <ATeamBenefits />
          <ATeamList workplaces={aTeamWorkplaces} isLoading={isATeamWorkplaceLoading ?? false} />
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
